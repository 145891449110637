@import "./../../assets/scss/settings";

.icon {

    svg {
        max-width: 62px;
        max-height: 62px;
        width: 100%;
        height: auto;
    }

    &--close {
        font-family: $icon-font;
        font-size: $font-size-xl;
        text-decoration: none;
        color: $text-color-light;

        &:before {
            content: "\f00d";
            top: -10px;
            position: relative;
        }

        &-popin {
            top: 0;

            &:before {
                top: 0;
            }

        }
    }
}
