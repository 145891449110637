// sass-lint:disable no-important
@import "../settings";

// responsive/adaptive helpers -------------------------------

//verstop op mobiel
.hide-on-small {
    @media (max-width: $mobile) {
        display: none !important;
    }
}

.hide-on-tabletsmall {
    @media (max-width: $tablet-small) {
        display: none !important;
    }
}

//verstop op tablet-resoluties
.hide-on-medium {
    @media (min-width: $mobile) and (max-width: $tablet) {
        display: none !important;
    }
}

//verstop op tablet landscape en groter
.hide-on-large {
    @media (min-width: $tablet) {
        display: none !important;
    }
}

// toon alleen op mobiel
.small-only {
    @media (min-width: $tablet-small) {
        display: none !important;
    }
}
