@import "./../../assets/scss/settings";


// deze variabele rekent de margins uit tussen de buttons
$button-group-gutter: $grid-margin-quarter;
$button-group-gutter-double: (#{$button-group-gutter} * 2);

.buttongroup {
    margin: -$button-group-gutter;
    width: calc(100% + #{$button-group-gutter-double});

    @media(max-width: $mobile-medium) {
        width: 100%;
        margin: 0;
    }

    &::after {
        clear: both;
        display: block;
        width: 100%;
        height: $button-group-gutter;
        content: "";
    }

    .button {
        margin: $button-group-gutter;
        max-width: calc(100% + #{$button-group-gutter-double});
        float: left;

        @media(max-width: $mobile-medium) {
            max-width: 100%;
        }
    }

    //50/50 buttons
    &--half {
        display: flex;
        flex-wrap: wrap;

        .button {
            width: calc(50% - #{$button-group-gutter-double});
            text-align: center;
            flex-grow: 1;
            flex-basis: auto;

            @media(max-width: $mobile-medium) {
                width: 100%;
            }
        }
    }
}
