@import "../settings";

// sass-lint:disable no-important

//tekstkleuren
.text-dark {
    color: $text-color-dark !important;
}

.text-muted {
    color: $text-color-muted !important;
}

.text-light {
    color: $text-color-light !important;
}

.text-ligher {
    color: $text-color-lighter !important;
}

.text-white {
    color: $white !important;
}

.text-danger {
    color: $invalid !important;
}

.text-primary {
    color: $primary-color !important;
}

.text-secondary {
    color: $secondary-color !important;
}
