@import "../settings";

html {
    font: normal normal #{$font-size}/#{$line-height} $primary-font;
    color: $text-color;
}

p {
    margin-bottom: $grid-margin-half;
}

small,
.small {
    font-size: $font-size-sm;
    line-height: $line-height;
}

.large {
    font-size: $font-size-lg;
    line-height: $line-height;

    @media(max-width: $mobile) {
        font-size: $font-size;
    }
}


//titels --------------------------------------
h1,
.h1 {
    font: normal bold #{$font-size-h1}/#{$line-height-sm} $secondary-font;
    color: $primary-color;
    margin-bottom: $grid-margin;

    @media(max-width: $mobile) {
        font-size: $font-size-h1-mobile;
    }

    &--mega {
        color: $secondary-color;
        font-size: $font-size-mega-h1;
        line-height: $line-height-1;
        font-style: italic;
        text-transform: uppercase;

        @media(max-width: $mobile) {
            font-size: $font-size-mega-h1-mobile;
        }
    }

    &--page-title {
        color: $secondary-color;
        font-size: $font-size-mega-h3;
        line-height: $line-height-1;
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: $letter-spacing;

        @media(max-width: $mobile) {
            font-size:$font-size-h2;
        }
    }
}

h2,
.h2 {
    font: normal bold #{$font-size-h2}/#{$line-height-sm} $secondary-font;
    color: $secondary-color;
    margin-bottom: $grid-margin-third;

    @media(max-width: $mobile) {
        font-size: $font-size-h2-mobile;
    }

    &--mega {
        //speciale grote subtitel op de homepage
        color: $primary-color;
        font-size: $font-size-mega-h2;
        line-height: $line-height-1;
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: $grid-margin-double;
        margin-left: -63px;
        margin-top: $grid-margin-double;

        span {
            display: block;
            margin-left: -40px;
        }

        @media(max-width: $tablet-medium) {
            font-size: 5rem;
            margin-left: -50px;
        }

        @media(max-width: $mobile) {
            font-size: $font-size-mega-h2-mobile;
            margin-top: -88px;
            margin-left: 0;
            text-align: right;
            color: $link-color;
            margin-bottom: $grid-margin;
        }
    }

    &--subtitle {
        font-size: 1.375rem;

        @media(max-width: $mobile) {
            font-size: 1rem;
        }
    }
}

h3,
.h3 {
    font: normal 600 #{$font-size-h3}/#{$line-height-sm} $primary-font;
    color: $secondary-color;
    margin-bottom: $grid-margin-third;

    &--team {
        color: $primary-color;
        font-weight: normal;
    }

    @media(max-width: $mobile) {
        font-size: $font-size-h3-mobile;
    }
}

h4,
.h4 {
    font: normal 600 #{$font-size-h4}/#{$line-height-sm} $primary-font;
    margin-bottom: $grid-margin-third;

    @media(max-width: $mobile) {
        font-size: $font-size-h4-mobile;
    }

    &--footer {
        //speciale titel voor de footer
        color: $white;
        font: italic bold #{$font-size-h4}/#{$line-height-sm} $secondary-font;
        text-transform: uppercase;
        letter-spacing: $letter-spacing;
    }
}

h5,
.h5 {
    font: normal 600 #{$font-size-h5}/#{$line-height-sm} $primary-font;
    text-transform: uppercase;
    margin-bottom: $grid-margin-third;
}

h6,
.h6 {
    font: normal 600 #{$font-size-h6}/#{$line-height-sm} $primary-font;
    color: $primary-color;
    margin-bottom: $grid-margin-third;
}
