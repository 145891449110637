@import "./../../assets/scss/settings";

$gallery-gutter: $grid-gutter-width / 2;

.gallery {
    &__item {
        padding-top: $gallery-gutter;
        padding-bottom: $gallery-gutter;
    }
}
