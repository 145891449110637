@import "../settings";

// achtergrondafbeeldingen axero ---------------------------------------------
.bg-tracks {
    background: url("../Assets/img/tracks.png") center center no-repeat, linear-gradient(to bottom left, $bg-light 0%, $white 50%);
}

.bg-graphic:not(.angle) {
    background: url("../Assets/img/bg-graphic.svg") center top -80px no-repeat;
    background-size: auto 1200px;
}

.bg-graphic.angle:after {
    background: url("../Assets/img/bg-graphic-light.svg") right top -80px no-repeat;
    background-size: auto 800px;
    background-color: inherit;

    @media (max-width: $tablet) {
        background-position: right -300px center;
        background-size: auto 700px;
    }

    @media (max-width: $mobile) {
        background-position: right -200px center;
        background-size: auto 350px;
    }
}

.bg-panel {
    background: $white;
    position: relative;

    &--details {
        margin-top: 15px;
        min-height: 500px;
        overflow: hidden;
        height: calc(100% - 15px);
    }
}

.bg-actions {
    height: 120px;
    width: 100%;

    @media(max-width: $mobile) {
        height: auto;
    }

    .button--actions {
        @media(max-width: $mobile) {
            float: none;
            margin-top: 15px;
            width: 100%;
        }
    }
}

.bg-page {
    min-height: 390px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    @media(max-width: $mobile) {
        min-height: 180px;
    }

    &:after {
        position: absolute;
        content: url(/Assets/img/page-header.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: -1;
        top: 0;
        left: 25%;
        right: 25%;
        bottom: 0;
        transform: translate(-50%, -50%);
    }
}

.bg-footer {
    //TODO tony: shorthand
    min-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background-image: url(/Assets/img/bg-footer.svg);
    background-repeat: no-repeat;
    background-size: 300px auto;
    background-position: right center;

    @media (max-width: $mobile) {
        background-position-y: 178px;
        background-size: 115px auto;
    }
}

// Schuine hoeken boven en onder vlakken
.angle {
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        transform-origin: right top;
        transform: skewY(5deg);
        background: $secondary-color;
    }

    &--top {

        //schuine hoek boven vanaf boven, links
        &::after {
            transform-origin: left top;
            transform: skewY(-5deg);
            background: $white;
        }

        &--light {
            &::after {
                transform-origin: left top;
                transform: skewY(-5deg);
                background: $bg-light;
            }
        }

        &--dark {
            width: 100%;

            &::after {
                transform-origin: left top;
                transform: skewY(-5deg);
                background: $primary-color;
            }
        }

    }

    &--cta {
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            transform-origin: right top;
            transform: skewY(5deg);
            background: $primary-color;
        }
    }

    &--bottom {

        &::after {
            bottom: 0;
            transform-origin: right bottom;
            transform: skewY(-5deg);
            background: $primary-color;
        }
    }

    &--border {

        &::after {
            width: 2px;
            left: -10px;
            transform-origin: left bottom;
            transform: skewX(-10deg);
            background: $secondary-color-dark;
        }

        &:first-child {
            &::after {
                display: none;
            }
        }

        @media (max-width: $mobile) {
            &:nth-child(odd) {
                &::after {
                    display: none;
                }
            }
        }
    }

    &--divider {
        height: 200px;
        margin-top: -200px;
        position: relative;
        z-index: 0;

        &:after {
            content: "";
            display: block;
            transform-origin: left top;
            transform: skewY(5deg);
            background: $white;
        }

        &--inv {
            margin-top: -90px;
            margin-bottom: -103px;

            &:after {
                transform: skewY(-5deg);
            }
        }
    }

    &--spacer:after {
        content: "";
        display: block;
        width: 100%;
        height: 20px;

        @media (min-width: $mobile) {
            height: 50px;
        }

        @media (min-width: $tablet) {
            height: 100px;
        }

        @media (min-width: $desktop) {
            height: 150px;
        }
    }
}

//Todo moeten we iets anders voor bedenken het werk verder wel.
.bg-popin {}

.highlight {
    border: 1px solid transparent;

    &:hover {
        cursor: pointer;
        border: 1px solid $quarternary-color;
    }
}

.shadow {
    box-shadow: $box-shadow;
}

.bg-filters {
    position: absolute;
    left: 15px;
    width: calc(100% - 30px);
}
