@import "../settings";

//Text helpers
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-xs-left {
    @media(max-width:$mobile-small) {
        text-align: left !important; // sass-lint:disable-line no-important
    }
}

.text-sm-left {
    @media(max-width:$mobile) {
        text-align: left !important; // sass-lint:disable-line no-important
    }
}

.text-md-left {
    @media(max-width:$tablet-medium) {
        text-align: left !important; // sass-lint:disable-line no-important
    }
}

.text-lg-left {
    @media(max-width:$desktop) {
        text-align: left !important; // sass-lint:disable-line no-important
    }
}

.text-xs-center {
    @media(max-width:$mobile-small) {
        text-align: center !important; // sass-lint:disable-line no-important
    }
}

.text-sm-center {
    @media(max-width:$mobile) {
        text-align: center !important; // sass-lint:disable-line no-important
    }
}

.text-md-center {
    @media(max-width:$tablet-medium) {
        text-align: center !important; // sass-lint:disable-line no-important
    }
}

.text-lg-center {
    @media(max-width:$desktop) {
        text-align: center !important; // sass-lint:disable-line no-important
    }
}


.text-normal {
    font-weight: normal;
}

.text-bold {
    font-weight: bold;
}

.no-link {
    text-decoration: none !important; // sass-lint:disable-line no-important
    color: inherit;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.text-col--2 {
    column-count: 2;
    column-gap: $column-gap;
}

// Line-Height helpers
.line-height-sm {
    line-height: $line-height-sm !important; // sass-lint:disable-line no-important
}

.line-height-lg {
    line-height: $line-height-lg !important; // sass-lint:disable-line no-important
}

.primary-font {
    font-family: $primary-font !important; // sass-lint:disable-line no-important
}

.secondary-font {
    font-family: $secondary-font !important; // sass-lint:disable-line no-important
}
