@import "./../../assets/scss/settings";

.field {
    $field: &;
    margin-bottom: calc(#{$grid-margin}*1.2);
    position: relative;
    display: flex;
    flex-flow: column wrap;

    &__label {
        transition: all $transition-duration ease-in-out;
        font-size: $font-size;
        color: $text-color;
        line-height: $line-height-base;
        width: 100%;
    }

    &__input {
        display: block;
        width: 100%;
        height: 3em;
        padding: $grid-padding-third $grid-padding-quarter $grid-padding-third 0;
        border: 0;
        border-bottom: 1px solid $border-medium;
        border-radius: 0;
        font-size: $font-size;
        line-height: $line-height-base;
        color: $text-color;
        outline: 0;
        appearance: none;
        transition: all $transition-duration ease-in-out;
        font-family: inherit;
        background: transparent;
        margin-top: -$grid-padding;

        &:focus {
            padding: $grid-padding-third $grid-padding-quarter $grid-padding-third 0;
            outline: 0;
        }

        // sass-lint:disable no-vendor-prefixes
        &[type=email]::-ms-clear,
        &[type=number]::-ms-clear,
        &[type=text]::-ms-clear,
        &[type=password]::-ms-reveal {
            display: none; // remove x in IE
        }

        &:-webkit-autofill {
            background: $white;
            -webkit-text-fill-color: $text-color !important; // sass-lint:disable-line no-important
        }

        // sass-lint:enable no-vendor-prefixes
        #{$field}:not(.pristine) &:required:invalid {
            border: 0;
            border-bottom: 1px solid $invalid;
        }

        &--inline {
            display: inline-block;
            width: auto;
            margin: 0;
            margin-top: 0;
        }
    }

    &__textarea {
        // change height of the textarea to make it smaller or bigger
        .field__input {
            overflow: auto; //fix IE scrollbar
            height: 6rem;
        }
    }

    &.dirty--quick {
        #{$field}__label,
        #{$field}__input {
            transition: none;
        }
    }

    &__phone {
        display: none;
    }
}

.focussed,
.dirty {
    .field__label {
        color: $primary-color;
        cursor: default;
        margin-top: -$grid-padding;
    }

    .field__input {
        margin-top: 0;
    }
}
