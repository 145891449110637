// Font import
$fa-font-path: "/Assets/fonts";

@font-face {
    font-family: "Font Awesome 5 Pro";
    src: url("#{$fa-font-path}/fa-regular-400.eot") format("embedded-opentype"), url("#{$fa-font-path}/fa-regular-400.woff") format("woff"), url("#{$fa-font-path}/fa-regular-400.ttf") format("truetype"), url("#{$fa-font-path}/fa-regular-400.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Font Awesome 5 Brands";
    src: url("#{$fa-font-path}/fa-brands-400.eot") format("embedded-opentype"), url("#{$fa-font-path}/fa-brands-400.woff") format("woff"), url("#{$fa-font-path}/fa-brands-400.ttf") format("truetype"), url("#{$fa-font-path}/fa-brands-400.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Font Awesome 5 Pro Solid";
    src: url("#{$fa-font-path}/fa-solid-900.eot") format("embedded-opentype"), url("#{$fa-font-path}/fa-solid-900.woff") format("woff"), url("#{$fa-font-path}/fa-solid-900.ttf") format("truetype"), url("#{$fa-font-path}/fa-solid-900.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}