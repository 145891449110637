@import "Input.scss";

$checkSize: 0.9rem;

.contact-form .field__input {
    margin-left: 0;
    width: auto;
}

.field {
    $field: &;

    margin-bottom: calc(2.25rem * 1.2);
    width: 100%;

    &__label {
        padding-left: .9rem;
    }

    &__input {
        padding-left: .9rem !important; // sass-lint:disable-line no-important
        width: calc(100% + 0.9rem);
        margin-left: -0.9rem;
        border: 1px solid $border-medium;
        margin-top: -2.25rem;

        &:focus {
            border-color: $link-color;
        }

        #{$field}:not(.pristine) &:required:invalid {
            border: 1px solid $invalid;
        }

        &--inline {
            margin: 0;
        }
    }

    &-validation-error {
        color: $invalid;
        margin-top: -5px;
        margin-bottom: 5px;
        display: block;
    }

    &__label-checkbox {
        position: relative;
        padding-left: 1.5rem;
        line-height: $line-height-sm;

        &:before {
            content: "";
            display: inline-block;
            background: $white;
            left: 0px;
            bottom: 2px;
            position: absolute;
            width: $checkSize;
            height: $checkSize;
            border: 1px solid $border-medium;
        }

        &:after {
            position: absolute;
            text-align: center;
            left: 0px;
            bottom: 2px;
            width: $checkSize;
            height: $checkSize;
            font-family: "Font Awesome 5 Pro",sans-serif;
            font-size: $font-size-xs;
            content: "\f00c"; //vinkje
            font-weight: 400;
            z-index: 1;
            opacity: 0;
            transition: color 0.25s, background-color 0.25s, opacity 0.25s;
        }

        #{$field}__checkbox {
            display: none;
        }

        &.checked {
            #{$field}__checkbox {
                background: $tertiary-color;
                border: 1px solid $tertiary-color;
            }

            &:after {
                background: $tertiary-color;
                color: $text-color-white;
                opacity: 1;
            }
        }
    }

    &--icon {
        position: relative;

        &:after {
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            font-family: $icon-font;
        }

        &--search:after {
            content: "\f002";
            padding-right: 7px;
            color: $quarternary-color;
        }

        &--dropdown:after {
            content: "\f0d7";
            position: absolute;
            font-family: "Font Awesome 5 Pro Solid";
            font-weight: 900;
            color: $tertiary-color;
            padding-right: 7px;
            pointer-events: none;
        }

        & > #{$field}__input {
            padding-right: 1.5rem;
        }
    }
}

.focussed,
.dirty {
    .field__label {
        margin-top: -2.25rem;
        color: $text-color;
        padding-left: 0;
        font-weight: bold;
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }

    .field__input {
        margin-left: 0;
        width: 100%;
    }
}

.input {
    padding: $grid-padding-third; // sass-lint:disable-line no-important
    border: 1px solid $border-medium;
    width: 100%;
    margin-bottom: $grid-margin-quarter;
    font-family: $primary-font;
    font-size: $font-size-sm;

    &::placeholder {
        font-family: $primary-font;
    }

    &-validation-error {
        border: 1px solid $invalid;
    }
}

select::-ms-expand { //sass-lint:disable-line no-vendor-prefixes
    display: none;
}

.validation-summary-errors {

    ul {
        padding: 0 0 0 1.1rem;
        color: $secondary-color;
        margin-bottom: 3rem;
    }
}

.show-address-form {
    .field__input {
        margin-left: 0;
    }

    .focussed .field__input,
    .dirty .field__input {
        width: calc(100% + .9rem);
    }
}
