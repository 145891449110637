@import "./../../assets/scss/settings";

$distance: 2px;

.spinner {
    $spinner: &;

    overflow: visible;
    fill: $secondary-color;

    &__item {
        animation-duration: 1.5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    &__up {
        animation-name: up;
    }

    &__left {
        animation-name: left;
    }

    &__right {
        animation-name: right;
    }

    &__downleft {
        animation-name: downleft;
    }

    &__downright {
        animation-name: downright;
    }

    &__center {
        animation-name: spin;
        transform-origin: center;
        animation-fill-mode: unset;
    }

    &__container {
        position: absolute;
        top: 0;
        left: $grid-padding;
        text-align: center;
        z-index: 10;
        height: 100%;
        width: calc(100% - #{$grid-padding});               

        #{$spinner} {
            width: 5rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
        } 

        &[data-spinner="search"] {
            #{$spinner} {
                position: fixed;
            }
        }

        &:before {
            content: "";
            display: block;
            background: $white;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            opacity: 0.8;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(1turn);
    }

    99.9999999999999% {
        transform: rotate(1turn);
    }

    100% {
        transform: rotate(0)
    }
}

@keyframes up {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-$distance);
    }

    60% {
        transform: translateY(0);
    }
}

@keyframes left {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-$distance);
    }

    60% {
        transform: translateX(0);
    }
}

@keyframes right {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX($distance);
    }

    60% {
        transform: translateX(0);
    }
}

@keyframes downleft {
    0% {
        transform: translate(0);
    }

    25% {
        transform: translate(-$distance, $distance);
    }

    60% {
        transform: translate(0);
    }
}

@keyframes downright {
    0% {
        transform: translate(0);
    }

    25% {
        transform: translate($distance, $distance);
    }

    60% {
        transform: translate(0);
    }
}
