// Het _settings.scss-bestand bevat de klant-specifieke instellingen.  Zie global voor basis-variabelen.
// Vergeet niet om _global.scss te updaten als je hier een nieuwe variabele aan toevoegt die algemeen inzetbaar is.
@import "./base/global";

// Media queries / sizes
// -------------------------

// Fonts/text
// -------------------------
$primary-font: "Source Sans Pro", Arial, Helvetica, sans-serif; //beschikbare varianten: regular (400), regular italic en semi-bold (600)
$secondary-font: univia-pro, "Source Sans Pro", Arial, Helvetica, sans-serif; //beschikbare varianten: Bold (700) en Ultra Italic (900 italic)
$icon-font: "Font Awesome 5 Pro", "Font Awesome 5 Pro Regular","Font Awesome 5 Brands", "Font Awesome 5 Solid" , "Font Awesome", "FontAwesome";

$font-size: 1rem; //16px
$font-size-xs: .75rem;
$font-size-sm: .875rem; //14px
$font-size-lg: 1.125rem; //18px
$font-size-xl: 1.5rem; //18px
$font-size-input: 1.125rem;

$line-height-1: 1;
$line-height-sm: 1.2;
$line-height-base: 1.5;
$line-height: 2;
$line-height-lg: 2.5;

$letter-spacing: .3rem;

$font-size-mega-h1: 10rem; //160px
$font-size-mega-h2: 6.875rem; //110px
$font-size-mega-h3: 6.250rem; //110px

$font-size-mega-h1-mobile: 2.5rem; //40px
$font-size-mega-h2-mobile: 3.125rem; //50px

$font-size-h1: 2.625rem; //42px
$font-size-h2: 2.25rem; //
$font-size-h3: 1.75rem; //
$font-size-h4: 1.25rem; //20
$font-size-h5: 1rem; //16 uppercase
$font-size-h6: 0.875rem; //14px mini zoals op de contactpagina

$font-size-h1-mobile: 1.75rem; //28px
$font-size-h2-mobile: 1.125rem; //18px
$font-size-h3-mobile: 1.125rem; //18px
$font-size-h4-mobile: 1rem; //16px

// Colors
// -------------------------
// sass-lint:disable no-color-hex
$primary-color: #262262;
$primary-color-dark: #131131;
$secondary-color: #ed145b;
$secondary-color-dark: #d41148;
$tertiary-color: #f99d1b;
$quarternary-color:#5590cc;
$bg-dark: $primary-color-dark;
$bg-muted:#faf8f4;
$bg-medium: #e8e2d3;
$bg-light: #f1f1f1;
$bg-transparent-dark: rgba(0, 0, 0, 0.3);

$text-color: #333;
$text-color-dark: #666;
$text-color-light: #999;
$text-color-lighter: #ccc;

$border-medium: #e4ddca;
$border-light: #f1eee4;

$link-color: #3f85bb;
$hover-color: darken($link-color, 10);

// Global margins/paddings
// -------------------------

//Navbar settings
// -------------------------
$navbar-bg-color: transparent;
$navbar-height: inherit;
$navbar-fixed-height: 120px;
$navbar-fixed-height-mobile: 85px;
$navbar-bg-color: transparent;
$navbar-font-family: $primary-font;
$navbar-font-size: $font-size-lg;
$navbar-text-color: $text-color;
$navbar-item-bg-color: transparent;
$navbar-item-active-bg-color: $secondary-color;
$navbar-item-bg-hover-color: $bg-light;
$navbar-item-padding-x: 10px;
$navbar-item-padding-y: 0px;
$navbar-item-margin-x: 15px;
$navbar-item-margin-y: 0px;
$navbar-link-color: $white;
$navbar-link-active-color: $secondary-color;
$navbar-link-hover-color: $link-color;
$navbar-toggler-bg-color: $primary-color;
$navbar-toggler-color: $white;
$navbar-toggler-border-radius: $border-radius;
$navbar-toggler-border-color: transparent;
$navbar-toggler-padding-x: 10px;
$navbar-toggler-padding-y: 10px;
$navbar-collapse-offset: 90px;
$navbar-collapse-bg-color: $primary-color;
$navbar-collapse-bg-alpha: 0.98;
$navbar-collapse-bg-rgba: rgba($navbar-collapse-bg-color, $navbar-collapse-bg-alpha);

// Sticky z-index
// -------------------------
$zindex-fixed: 999;
$zindex-sticky: 999;

//other global measurements
// -------------------------

