@import "../base/global";

.image {
    max-width: 100%;

    &--responsive {
        display: block;
        width: 100%;
    }

    &--link {
        // for a tags around images
        display: block;
        max-width: 100%;
    }

    &--w-xs {
        width: $image-max-w-xs;
    }

    &--w-sm {
        width: $image-max-w-sm;
    }

    &--w-md {
        width: $image-max-w-md;
    }

    &--w-lg {
        max-width: $image-max-w-lg;
    }

    &--w-xl {
        width: $image-max-w-xl;
    }

    &--h-xs {
        height: $image-max-h-xs;
    }

    &--h-sm {
        height: $image-max-h-sm;
    }

    &--h-md {
        height: $image-max-h-md;
    }

    &--h-lg {
        height: $image-max-h-lg;
    }

    &--h-xl {
        height: $image-max-h-xl;
    }

    &--th-16 {
        max-width: 16px;
        height: auto;
        width: 16px;
    }

    &--th-24 {
        max-width: 24px;
        height: auto;
        width: 24px;
    }

    &--th-32 {
        max-width: 32px;
        height: auto;
        width: 32px;
    }

    &--th-48 {
        max-width: 48px;
        height: auto;
        width: 48px;
    }

    &--th-64 {
        max-width: 64px;
        height: auto;
        width: 64px;
    }

    &--th-128 {
        max-width: 128px;
        height: auto;
        width: 128px;
    }

    &--th-50 {
        max-width: 50px;
        height: auto;
        width: 50px;
    }

    &--th-75 {
        max-width: 75px;
        height: auto;
        width: 75px;
    }

    &--th-100 {
        max-width: 100px;
        height: auto;
        width: 100px;
    }

    &-wrapper {
        height: 0px;

        &--twothird {
            padding-bottom: percentage(2/3);
        }
    }
}
