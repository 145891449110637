body,
html,
div,
span,
object,
embed,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

article,
blockquote,
section,
main {
    display: block;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a,
label,
input[type=submit],
input[type=radio],
input[type=file],
select,
button {
    cursor: pointer;
}

input[type=submit],
button {
    -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

address {
    font-style: normal;
}

nav {
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        text-decoration: none;
    }
}
