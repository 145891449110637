@import "./../../assets/scss/settings";

.pager {
    overflow: hidden;

    @media(max-width: $mobile-small) {
        //centered op mini-mobiel, is mooier
        display: flex;
        justify-content: center;
    }

    &__item {
        float: left;
        margin-right: 1px;
        margin-bottom: 1px;
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        border: none;
        background-color: $white;
        text-decoration: none;

        //op mini mobiele resoluties tonen we alleen prev/next en huidig.
        @media(max-width: $mobile-small) {
            display: none;

            &--prev,
            &--next,
            &--current {
                display: inline-block;
            }
        }

        &:hover,
        &:focus {
            color: $white;
            background-color: $link-color;
        }

        &--inactive {
            color: $text-color-muted;

            &:hover,
            &:focus {
                background-color: $text-color-muted;
            }
        }

        &--current {
            box-shadow: inset 0 0 0 1px $link-color;
            color: $link-color;

            &:hover {
                color: $link-color;
                background-color: $white;
                cursor: pointer;
            }
        }

        &--prev {
            font-size: .6rem;
            font-weight: bold;

            &::before {
                font-family: $icon-font;
                content: "\f053";
                margin-top: 3px;
            }
        }

        &--next {
            font-size: .6rem;
            font-weight: bold;

            &::before {
                font-family: $icon-font;
                content: "\f054";
            }
        }

    }

}
