@import "../settings";

//img responsive fix
img {
    max-width: 100%;
    height: auto;
}

//lijsten  ---------------------------------------
ul,
ol {

}

dl {
    dt {
        font-weight: bold;
    }

    dd {
        font-style: italic;
        margin-bottom: .5em; //todo: var margins gebruiken
    }
}

//links -----------------------
a {
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

//divider ----------------------
hr {
    height: 1px;
    border: 0;
    border-top: 1px solid $border-medium;
    margin: 1.1em 0; //todo: var margins gebruiken
}

// geselecteerde tekst ---------------------------------------------
::selection {
    background: $primary-color;
    color: $text-color;
}

// sass-lint:disable no-vendor-prefixes
::-moz-selection {
    background: $primary-color;
    color: $text-color;
}

// sass-lint:enable no-vendor-prefixes

