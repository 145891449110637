// sass-lint:disable no-important
@import "../settings";

//position helpers
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.sticky-top {
    position: fixed;
    top: 0;
    z-index: $zindex-sticky;
}

.sticky-side {
    position: fixed;
}

.relative {
    position: relative;
}

.abs-left {
    position: absolute;
    left: 0;
}

.abs-right {
    position: absolute;
    right: 0;

  }

.abs-top {
    position: absolute;
    top: 0;
  
}

.abs-bottom {
    position: absolute;
    bottom: 0;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

//TODO: aanvullen met andere uitlijningen zoals sticky-top, sticky-bottom, etc.
