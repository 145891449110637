@import "./../../assets/scss/settings";

//default navs
.nav {
    $nav: &;

    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        display: block;
        padding-right: $grid-padding-third;
        padding-left: $grid-padding-third;
        color: $text-color;
    }

    &__link {
        display: block;
        color: $link-color;

        &:focus {
            background: transparent;
            color: $tertiary-color;
        }

        #{$nav}--top #{$nav} & {
            &--active {
                background-color: transparent;
                color: $secondary-color !important; //sass-lint:disable-line no-important
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                    color: darken($secondary-color, 10%) !important; //sass-lint:disable-line no-important

                }
            }
        }
    }

    &--list {
        flex-direction: column;

        #{$nav}__item {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &--breadcrumb {
        padding: 0;

        #{$nav}__item {
            display: list-item;
            padding: $grid-padding-quarter 0;
            color: $text-color-light;

            &:first-child {
                text-decoration: none;
                font-weight: bold;
                color: $text-color-light;
            }

            &:first-child::before {
                content: "";
            }

            &:before {
                display: inline-block;
                padding-right: .7rem;
                padding-left: .7rem;
                color: $text-color;
                content: "/";
            }
        }
    }

    &--secondary {
        padding: 0;

        #{$nav}__item {
            display: flex;
            padding: 0;

            &:first-child:before {
                content: "";
                margin-left: -$grid-padding-half;
            }

            &:before {
                padding-left: $grid-padding-quarter;
                padding-right: $grid-padding-quarter;
                content: "/";
            }
        }
    }

    // Ga ik nog aanpassen

    &--top {
        $nav: &;

        height: 40px;
    }

    &--menu {
        $nav: &;

        height: 80px;

        @media (max-width: $tablet-medium) {
            height:65px;
            position: relative;
        }

        @media (max-width: $mobile) {
            height:50px;
        }
    }

    &--collapse {
        position: relative;
        cursor: pointer;
        transition: all .25s ease;
        padding: $grid-padding-half;
        background: transparent;
        margin-bottom: 0;
        border-top: 1px solid $bg-light;
        border-bottom: 1px solid $bg-light;
        text-transform: inherit;
        margin-top: -1px;

        &[disabled] {
            pointer-events: none;
            color: $text-color-muted;
            background-color: $bg-muted;

            &:after {
                color: $text-color-muted;
            }
        }

        &:after {
            content: "\f0d7";
            position: absolute;
            font-family: "Font Awesome 5 Pro Solid";
            font-weight: 900;
            top: 31%;
            transition: 0.3s;
            margin-left: 15px;
            right: $grid-padding-half;
            color: $tertiary-color;
        }

        &.expanded {
            &:after {
                transform: rotate(180deg) translate(0, 0);
            }

            & + .nav--collapse__content {
                display: block;
            }
        }

        &__content {
            padding: $grid-padding-quarter $grid-padding-half;
            display: none;
        }
    }

    &--active {
        background: $primary-color;
        color: $white;
    }
}
