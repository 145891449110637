@import "./Slider";

.slider {
    $slider: &;

    height: 420px;
    padding-top: 120px;
    overflow: hidden;

    @media(min-width: $tablet-small) {
        height: 480px;
    }

    @media (min-width: $desktop) {
        height: 550px;
    }

    @media (min-width: $desktop-large) {
        height: 700px;
    }

    &__items {
        height: 100%;

        .owl-stage-outer {
            height: inherit;
        }
    }

    &__item {
        min-height: 450px;
        padding-top: $grid-padding-half;

        @media (max-width: $mobile) {
            min-height: inherit;
        }

        @media (min-width: $desktop-large) {
            padding-top: $grid-padding-double;
        }

        .h1 {
            @media (max-width: $mobile) {
                margin-bottom: $grid-margin-half;
            }
        }
    }

    &__image {
        position: absolute;
        right: -100px;
        z-index: 10;
        width: auto !important; //sass-lint:disable-line no-important
        transition: all 0.5s ease;
        max-width: 100%;
        right: -60px;
        bottom: -110px;

        @media (min-width: $mobile-small) {
            max-width: 90%;
        }

        @media (min-width: $mobile-medium) {
            max-width: 80%;
            right: -20px;
        }

        @media (min-width: 600px) {
            max-width: 475px;
            right: -10px;
        }

        @media (min-width: $tablet-small) {
            max-width: 420px;
            bottom: auto;
            top: 0;
        }

        @media (min-width: $tablet-large) {
            max-width: 420px;
        }

        @media (min-width: $desktop) {
            max-width: 520px;
            right: -115px;
        }

        @media (min-width: $desktop-large) {
            max-width: 100%;
        }
    }

    &__title {
        @media (min-width: $tablet-small) and (max-width: $tablet) {
            font-size: 5rem;
        }

        @media (min-width: $tablet) and (max-width: $desktop) {
            font-size: 6rem;
        }

        @media (min-width: $desktop) {
            font-size: 8rem;
        }
    }

    &__description {

        @media(max-width: $tablet-medium) {
            position: relative;
            z-index: 999;
            text-shadow: 0 1px 0 $primary-color;
        }
    }

    &__nav {
        margin-top: 80px;
    }

    &__button {
        position: relative;
        left: auto !important; //sass-lint:disable-line no-important
        right: auto !important; //sass-lint:disable-line no-important
        border: none;
        box-shadow: none;
        color: $white;
        font-size: 1.2rem;
        padding: 1.2rem !important; //sass-lint:disable-line no-important

        &:hover,
        &:visited,
        &:active {
            background: inherit;
        }
    }

    &__buttons {
        position: absolute;
        right: 0;
        top: 15vh;
        display: flex;
        flex-direction: column;

        @media (min-width: $desktop) {
            top: 20vh;
        }

        .owl-prev #{$slider}__button {
            border-bottom: 1px solid $border-light;
        }
    }

    &__dots {
        margin-top: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: $mobile) {
            bottom: 10px;
        }

        .owl-dot {
            background: darken($white, 50%);
            border: none;
            width: 0.5rem;
            height: 0.5rem;
            padding: 0;
            margin: 7.5px;

            &:hover,
            &.active {
                background: $white;
                box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.15); //sass-lint:disable-line no-color-literals

            }
        }
    }
}


//OWL CAROUSEL TRANSITIE PROBLEEM FIX
.carslider.owl-carousel .owl-stage {
    transition: .5s !important; // sass-lint:disable-line no-important
}
