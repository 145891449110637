@import "./../../assets/scss/settings";

.navbar {
    background: $navbar-bg-color;
    height: $navbar-height;
    display: flex;
    position: relative;

    @media(max-width: $tablet-medium) {
        position: initial;
    }

    &-brand {
        display: flex;
        align-items: center;

        img,
        svg {
            //logo wat kleiner
            width: 150px;
            display: flex;
        }

        @media(max-width: $mobile) {

            img,
            svg {
                //logo wat kleiner
                width: 100px;
                display: flex;
                height: 50px;
            }
        }
    }

    &--fixed {
        height: $navbar-fixed-height;
        width: 100%;

        @media(max-width: $mobile) {
            height: $navbar-fixed-height-mobile;
            min-height: $navbar-fixed-height-mobile;
        }

        + .main {
            padding-top: $navbar-fixed-height;

            @media(max-width: $mobile) {
                padding-top: $navbar-fixed-height-mobile;
            }
        }

        + .no-slider {
            margin-top: $grid-padding;
        }
    }

    &__toggler {
        background-color: transparent;
        display: none;
        border: 1px solid $navbar-toggler-border-color;
        outline: none;

        @media(max-width: $tablet-medium) {
            display: flex;
            position: absolute;
            right: 50px;
            float: right;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__collapse {
        display: flex;

        @media(max-width: $tablet-medium) {
            display: none;
            position: fixed;
            top: $navbar-collapse-offset;
            left: 0;
            background: $navbar-collapse-bg-rgba;
            width: 100%;
            z-index: $zindex-fixed;
            box-shadow: $box-shadow;
            height: 100vh;
            overflow-y: scroll;
        }

        @media(max-width: $mobile) {
            top: $navbar-collapse-offset;
        }
    }

    &__notification {
        background-color: $secondary-color;
        border-radius: 100%;
        font-family: $navbar-font-family;
        font-weight: bold;
        color: $text-color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        text-align: center;
        position: absolute;
        top: 3px;
        right: -20px;
        transition: font-size 0.5s ease-in-out;
    }

    & .nav {
        list-style: none;

        @media (max-width: 640px) {
            text-align: center;
        }

        &__item {
            padding-left: 0;
            font-family: $navbar-font-family;
            font-weight: bold;
            position: relative;
            line-height: $line-height-sm;
            margin: $navbar-item-margin-y $navbar-item-margin-x;
            text-transform: uppercase;

            @media (max-width: $tablet-medium) {
                padding-right: 0;
                margin-top: $navbar-item-margin-x;
                margin-bottom: $navbar-item-margin-x;
            }

            &:hover {
            }
        }

        &__link {
            color: $navbar-link-color;
            padding: $navbar-item-padding-x $navbar-item-padding-y;
            display: inline-block;
            text-decoration: none;
            font-family: $navbar-font-family;
            position: relative;

            @media (max-width: $tablet-medium) {
                padding-right: 0;
                display: inline-block;
            }

            &:hover {
                text-decoration: none;
                color: $navbar-link-hover-color;
            }

            &--active {
                background-color: transparent;
                border-bottom: 2px solid $navbar-item-active-bg-color;

                &:hover {
                    text-decoration: none;
                    color: $navbar-link-hover-color;
                }
            }
        }
    }
}

.hamburger {
    $hamburger: &;
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;

    &__wrap {
        width: 100%;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    &__inner {
        display: block;
        top: 50%;
        margin-top: $hamburger-layer-height / -2;

        &,
        &::before,
        &::after {
            width: $hamburger-width;
            height: $hamburger-layer-height;
            background-color: $navbar-toggler-color;
            border-radius: $navbar-toggler-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            left: -5px;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            left: -10px;
        }
    }

    &--animation {

        #{$hamburger}__inner {
            top: $hamburger-layer-height / 2;
            transition: background-color 0s 0.13s linear;

            &::before {
                top: $hamburger-layer-height + $hamburger-layer-spacing;
                transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &::after {
                top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
                transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
        }
    }

    &--active {
        #{$hamburger}__inner {
            transition-delay: 0.22s;
            background-color: transparent;

            &::before {
                top: 0;
                left: 0;
                transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
            }

            &::after {
                top: 0; ///
                left: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
            }
        }
    }
}
