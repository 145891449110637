﻿@import "./../../assets/scss/settings";

.field {
    $field: &;

    position: relative;

    &__dropdown--range {
        border: 1px solid $border-medium;

        #{$field}__label {
            display: flex;
            min-height: 40px;
            flex-direction: row;
            align-items: center;
            align-self: center;

            @media (max-width: $tablet-small) {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }

            @media (max-width: $mobile-small) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-top: 5px;
            }

            &__span {
                margin-right: 0;

                @media (min-width: $tablet-small) {
                    margin-right: auto;
                }
            }

            &--dropdown {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 35px;
            }
        }

        #{$field}__dropdown {
            width: auto;
            min-width: 70px;
            border: none;
        }
    }

    &__dropdown {
        width: 100%;
        border: 1px solid $border-medium;
        background: $white;

        @at-root div#{&} {
            display: none;

            &:not(:disabled):not(#{$field}__dropdown--collapsed) {
                display: block;
                position: absolute;
                z-index: 1;
                top: 2.8rem;

                + #{$field}__label {
                    #{$field}__arrow {
                        transform: rotateX(180deg);
                    }
                }
            }
        }

        @at-root select#{&} {
            appearance: none;
            padding: .5rem;
            color: $text-color-dark;
            font: normal normal #{$font-size} / #{$line-height-base} $primary-font;
        }

        &:focus {
            outline: $primary-color auto 5px;
        }

        &[disabled] {
            & + #{$field}__label {
                pointer-events: none;
                color: $text-color-muted;
                background-color: $bg-muted;

                #{$field}__dropdown__arrow {
                    fill: $text-color-muted;
                }
            }
        }

        &__arrow {
            fill: $tertiary-color;
            transition: all 0.5s ease;
            position: absolute;
            right: calc(#{$grid-padding-double} / 8);
            top: 50%;
            transform: translateY(-50%);
            width: 0.75rem;
            font-size: .9rem;
        }

        &__group {
            background: $bg-light;

            &__header {
                display: block;
                text-transform: uppercase;
                padding: 0.5rem;
                border-bottom: 1px solid $border-medium;
            }
        }

        &__option {
            background: $white;
            text-transform: capitalize;
            margin: 0;
            margin-bottom: 0 !important; //sass-lint:disable-line no-important
            padding: 0.5rem;
            border-bottom: 1px solid $border-medium;
        }

        @at-root & + #{$field}__label {
            position: relative;
            background: $white;
            border: 1px solid $border-medium;
            padding: $grid-padding-third;
            user-select: none;
            order: -1;
        }
    }
}

.custom-dropdown {
    width: 70px;
    height: 40px;
    position: relative;
    appearance: none;
    border: none;

    &::after {
        content: "\f0d7";
        position: absolute;
        font-family: "Font Awesome 5 Pro Solid";
        font-weight: 900;
        color: $tertiary-color;
        top: 8px;
        right: 10px;
        pointer-events: none; // Fix om pijltje klikbaar te maken met absolute
    }

    &__select {
        background: transparent;
        border: none;
        height: 40px;
        font: normal normal #{$font-size} / #{$line-height-base} $primary-font;
        border: 0;
        border-radius: 0;
        appearance: none;
        width:100%;
    }
}
