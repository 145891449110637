@import "global";

html {
    font: normal normal #{$font-size}/#{$line-height-base} $primary-font;
    color: $text-color;
}

p {
    margin-bottom: $grid-margin-half;
    word-break: break-word;
}

small,
.small {
    font-size: $font-size-sm;
    line-height: $line-height-base;
}

.medium {
    font-size: $font-size-md;
    line-height: $line-height-base;
}

.large {
    font-size: $font-size-lg;
    line-height: $line-height-base;
}

.xlarge {
    font-size: $font-size-xl;
    line-height: $line-height-base;
}

//titels --------------------------------------
h1,
.h1 {
    font: normal bold #{$font-size-h1}/#{$line-height-sm} $secondary-font;
    margin-bottom: $grid-margin-third;
}

h2,
.h2 {
    font: normal bold #{$font-size-h2}/#{$line-height-sm} $secondary-font;
    margin-bottom: $grid-margin-third;
}

h3,
.h3 {
    font: normal bold #{$font-size-h3}/#{$line-height-sm} $secondary-font;
    margin-bottom: $grid-margin-third;
}

h4,
.h4 {
    font: normal bold #{$font-size-h4}/#{$line-height-sm} $secondary-font;
    margin-bottom: $grid-margin-third;
}

h5,
.h5 {
    font: normal bold #{$font-size-h5}/#{$line-height-sm} $secondary-font;
    margin-bottom: $grid-margin-third;
}

h6,
.h6 {
    font: normal bold #{$font-size-h6}/#{$line-height-sm} $secondary-font;
    margin-bottom: $grid-margin-third;
}

.subheader {
    font-family:$secondary-font!important; // sass-lint:disable-line no-important
}
