// Het global.scss-bestand wordt gebruikt als een globaal settings-bestand. Het settings-bestand bevat de klant-specifieke instellingen.

// Uitleg !default:
// You can assign to variables if they aren’t already assigned by adding the !default flag to the end of the value.
// This means that if the variable has already been assigned to, it won’t be re-assigned, but if it doesn’t have a value yet, it will be given one.
// So using Sass !default is like adding an “unless this is already assigned” qualifier to your variable assignments.

// Media queries / sizes
// -------------------------
$desktop: 1200px !default;
$desktop-large: 1680px !default;

$tablet: 1024px !default;
$tablet-large: 1025px !default;
$tablet-medium: 992px !default;
$tablet-small: 768px !default;

$mobile: 767px !default;
$mobile-medium: 480px !default;
$mobile-small: 320px !default;

// Grid variables
// -------------------------
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$container-sm: 720px + $grid-gutter-width !default;
$container-md: 940px + $grid-gutter-width !default;
$container-lg: 1140px + $grid-gutter-width !default;
$grid-gutter-compensation: ($grid-gutter-width / 2) * -1 !default;

// Global margins/paddings
// -------------------------
$grid-margin: $grid-gutter-width !default;
$grid-margin-half: $grid-margin / 2 !default;
$grid-margin-third: $grid-margin / 3 !default;
$grid-margin-quarter: $grid-margin / 4 !default;
$grid-margin-double: $grid-margin * 2 !default;

$grid-padding: 1.875rem !default;
$grid-padding-half: $grid-padding / 2 !default;
$grid-padding-third: $grid-padding / 3 !default;
$grid-padding-quarter: $grid-padding / 4 !default;
$grid-padding-double: $grid-padding * 2 !default;

// Grid breakpoints
// -------------------------
$screen-xs-min: $mobile-small !default;
$screen-sm-min: $mobile !default;
$screen-md-min: $tablet-medium !default;
$screen-lg-min: $desktop !default;
$screen-xl-min: $desktop-large !default;

// Fonts/text
// -------------------------
$primary-font: Arial, Helvetica, sans-serif !default;
$secondary-font: Arial, Helvetica, sans-serif !default;
$tertiary-font: Arial, Helvetica, sans-serif !default;
$icon-font: "Font Awesome 5 Pro", "Font Awesome 5 Pro Regular","Font Awesome 5 Brands", "Font Awesome 5 Solid", "Font Awesome 5 Pro Solid", "Font Awesome", "FontAwesome" !default; //let op: deze variant gebruikt font-weight: 400 (normal); Werkt nog niet ivm crossdomain error

$font-size: 1rem !default;
$font-size-xs: .75rem !default;
$font-size-sm: .875rem !default;
$font-size-md: 1.125rem !default;
$font-size-lg: 1.25rem !default;
$font-size-xl: 1.5rem !default;
$font-size-input: 1.125rem !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$line-height-sm: 1.2 !default;
$line-height-base: 1.5 !default;
$line-height-lg: 2 !default;

$font-size-h1: 2.75rem !default;
$font-size-h2: 2.25rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$subheader-font-family: $secondary-font !default;
$subheader-font-weight: $font-weight-bold !default;

// Colors
// -------------------------
// sass-lint:disable no-color-hex
$primary-color: #ffd503 !default;
$secondary-color: #38a9a9 !default;
$tertiary-color: #EB1E23 !default;
$quarternary-color: #5590cc !default;

$primary-rgba-bg-color: $primary-color !default;
$primary-rgba-bg-alpha: 0.98 !default;
$primary-rgba-color: rgba($primary-rgba-bg-color, $primary-rgba-bg-alpha) !default;

$black: #000000 !default;
$white: #ffffff !default;

$bg-dark: #666 !default;
$bg-medium: #b8b8b8 !default;
$bg-light: #eaeaea !default;
$bg-muted: #f1f1f1 !default;

$text-color: #454545 !default;
$text-color-dark: #000000 !default;
$text-color-muted: #cccccc !default;
$text-color-light: #999 !default;
$text-color-white: $white;

$invalid: #ee4336 !default;
$success: #7fad4d !default;

$link-color: #5590cc !default;
$hover-color: lighten($primary-color, 5%);

$headings-color: $text-color !default;
$headings-font-family: $primary-font !default;
$headings-line-height: $line-height-base !default;

$border-dark: #454545 !default;
$border-medium: #666 !default;
$border-light: #999 !default;
$border-mute: #ccc !default;
$border-width: 1px !default;
$border-color: $border-medium !default;
$border-radius: 4px !default;

$hr-border-color: #ccc !default;
$hr-border-width: $border-width !default;

//Transition settings
// -------------------------
$transition-duration: .4s !default;
$transition-easing: ease !default;

//Navbar settings
// -------------------------
$navbar-height: 50px !default;
$navbar-fixed-height: 100px !default;
$navbar-fixed-height-mobile: 70px !default;
$navbar-bg-color: $primary-color !default;
$navbar-font-family: $primary-font !default;
$navbar-font-size: $font-size !default;
$navbar-text-color: $text-color !default;
$navbar-item-bg-color: #FFF !default;
$navbar-item-active-bg-color: $primary-color !default;
$navbar-item-bg-hover-color: $bg-light !default;
$navbar-item-padding-x: 15px !default;
$navbar-item-padding-y: 20px !default;
$navbar-item-margin-x: 30px !default;
$navbar-item-margin-y: 0px !default;
$navbar-link-color: $link-color !default;
$navbar-link-active-color: $link-color !default;
$navbar-link-hover-color: $link-color !default;
$navbar-toggler-bg-color: $primary-color !default;
$navbar-toggler-color: $text-color !default;
$navbar-toggler-border-radius: $border-radius !default;
$navbar-toggler-border-color: $border-mute !default;
$navbar-toggler-padding-x: 10px !default;
$navbar-toggler-padding-y: 10px !default;
$navbar-collapse-offset: 120px !default;
$navbar-collapse-bg-color: $primary-color !default;
$navbar-collapse-bg-alpha: 0.98 !default;
$navbar-collapse-bg-rgba: rgba($navbar-collapse-bg-color, $navbar-collapse-bg-alpha);

// Hamburger settings
// -------------------------
$hamburger-width: 32px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// Buttons
// -------------------------

$btn-padding-y: 0.75rem !default;
$btn-padding-x: 0.75rem !default;
$btn-font-size: $font-size !default;
$btn-font-weight: bold !default;
$btn-font-family: $primary-font !default;
$btn-line-height: $line-height-base !default;
$btn-border-radius: 0 !default;
$btn-transition: all $transition-duration $transition-easing;
$btn-fixed-size: 18.75rem !default;

// Primary button
$btn-bg-color: $primary-color;
$btn-bg-hover-color: lighten($primary-color, 1%) !default;
$btn-text-color: #fff !default;
$btn-text-hover-color: #fff !default;

// Secondary button
$btn-bg-color-secondary: $secondary-color !default;
$btn-bg-hover-color-secondary: lighten($secondary-color, 5%) !default;
$btn-text-color-secondary: #fff !default;
$btn-text-hover-color-secondary: #fff !default;

// Ghost button
$btn-bg-color-ghost: transparent !default;
$btn-bg-hover-color-ghost: $secondary-color !default;
$btn-text-color-ghost: $secondary-color !default;
$btn-text-hover-color-ghost: $white !default;

// Inverted button
$btn-bg-color-inverted: $white !default;
$btn-bg-hover-color-inverted: $primary-color !default;
$btn-text-color-inverted: $primary-color !default;
$btn-text-hover-color-inverted: $white !default;

// Active button
$btn-bg-color-active: $secondary-color !default;
$btn-bg-hover-color-active: $primary-color !default;
$btn-text-color-active: $white !default;
$btn-text-hover-color-active: $white !default;

// Cards settings
// -------------------------
$card-radius: 0 !default;
$card-font-size: $font-size !default;
$card-intro-font-weight: bold !default;
$card-font-family: $primary-font !default;

// Figure settings
// -------------------------
$figure-font-size: $font-size !default;
$figure-font-weight: bold !default;
$figure-font-family: $primary-font !default;
$figure-text-color: $text-color !default;
$figure-caption-padding: $grid-padding-quarter !default;

//other global measurements
/*// -------------------------*/

$slider-button-radius: 50% !default;
$slider-nav-radius: 50% !default;

// Image max-widths & max-heights
// -------------------------
$image-max-w-xxs: 75px !default;
$image-max-w-xs: 120px !default;
$image-max-w-sm: 150px !default;
$image-max-w-md: 240px !default;
$image-max-w-lg: 320px !default;
$image-max-w-xl: 640px !default;

$image-max-h-xs: 120px !default;
$image-max-h-sm: 150px !default;
$image-max-h-md: 300px !default;
$image-max-h-lg: 640px !default;
$image-max-h-xl: 800px !default;

// BG images
// -------------------------
$bg-image-height-xs: 20vh !default;
$bg-image-height-sm: 30vh !default;
$bg-image-height-md: 40vh !default;
$bg-image-height-lg: 50vh !default;

// box shadow
// -------------------------
$box-shadow-color: rgba(0, 0, 0, .2) !default;
$box-shadow: 0px 15px 20px -8px $box-shadow-color !default;

// Sticky z-index
// -------------------------
$zindex-fixed: 999 !default;
$zindex-sticky: 999 !default;

// Column Gap
// -------------------------
$column-gap: 60px;
