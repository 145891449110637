@import "../base/global";


// Mixin to media queries
@mixin media($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

// Framework grid generation
@mixin container-fixed {
    margin-right: auto;
    margin-left: auto;
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
    width: 100%;
    position: relative;
}

@mixin make-row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $grid-gutter-compensation;
    margin-left: $grid-gutter-compensation;
}

// Bootstrap col-xs-4 etc
@mixin make-grid-columns($class) {

    $list: ".col-#{$class}";

    @for $i from 1 through $grid-columns {
        $list: "#{$list}, .col-#{$class}-#{$i}";
    }

    $list-offset: ".col-#{$class}-offset-0";

    @for $i from 1 through $grid-columns {
        $list-offset: "#{$list-offset}, .col-#{$class}-offset-#{$i}";
    }

    #{$list},
    #{$list-offset} {
        flex: 0 0 auto;
        padding-left: ceil(($grid-gutter-width / 2));
        padding-right: floor(($grid-gutter-width / 2));
    }
}

@mixin calc-grid-column($index, $class, $type) {
    @if ($type == width) and ($index == 0) {
        .col-#{$class} {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
        }
    }

    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            flex-basis: percentage(($index / $grid-columns));
            max-width: percentage(($index / $grid-columns));
        }
    }

    @if ($type == offset) and ($index < $grid-columns) {
        .col-#{$class}-offset-#{$index} {
            margin-left: percentage(($index / $grid-columns));
        }
    }
}

@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type);
    }
}

// Create grid for specific class
@mixin make-grid($class) {
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, offset);
}

//Create container's
.container {
    @include container-fixed;

    &--fixed-sm {
        max-width: $container-sm;
    }

    &--fixed-md {
        max-width: $container-md;
        min-height: 300px; // Min height to prevent overlay over the content
    }

    &--fixed-lg {
        max-width: $container-lg;
    }
}

//Create row/col
.row {
    @include make-row;

    // No gutter reset
    &--nogutters {
        margin-left: 0;
        margin-right: 0;

        [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
            //@include media($screen-md-min) {
            // width: 100%;
            //}
        }
    }
}

@mixin make-helpers($class) {
    .start-#{$class} {
        justify-content: flex-start;
        text-align: start;
    }

    .center-#{$class} {
        justify-content: center;
        text-align: center;
    }

    .end-#{$class} {
        justify-content: flex-end;
        text-align: end;
    }

    .top-#{$class} {
        align-items: flex-start;
    }

    .middle-#{$class} {
        align-items: center;
    }

    .bottom-#{$class} {
        align-items: flex-end;
    }

    .around-#{$class} {
        justify-content: space-around;
    }

    .between-#{$class} {
        justify-content: space-between;
    }

    .first-#{$class} {
        order: -1;
    }

    .reset-order-#{$class} {
        order: 0;
    }

    .last-#{$class} {
        order: 1;
    }
}

//Create grid columns
@include make-grid-columns(xs);
@include make-grid(xs);
@include make-helpers(xs);

@include media($screen-sm-min) {
    @include make-grid-columns(sm);
    @include make-grid(sm);
    @include make-helpers(sm);
}

@include media($screen-md-min) {
    @include make-grid-columns(md);
    @include make-grid(md);
    @include make-helpers(md);
}

@include media($screen-lg-min) {
    @include make-grid-columns(lg);
    @include make-grid(lg);
    @include make-helpers(lg);
}

@media (max-width: 512px) {
    @for $i from 0 through $grid-columns {
        .col-xxs-#{$i} {
            flex-basis: percentage($i / $grid-columns);
            max-width: percentage($i / $grid-columns);
        }
    }
}

[class*="col-sm"],
[class*="col-md"],
[class*="col-lg"] {
    // flex: 0 0 auto;
    width: 100%;
    min-height: 1px;
    padding-left: ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
}
