@import "./../../assets/scss/settings";

$distance: 2px;

.spinner {

    @media (max-width: $mobile) {
        &__container {
            left: 0;
            width: 100%;

            .spinner {
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }
}
