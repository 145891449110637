@import "../../Assets/scss/settings";

.faq {
    $faq: &;

    font-size: 1.2rem;

    &--collapsed {
        #{$faq}__question {
            background: $white;
        }

        #{$faq}__arrow {
            transform: rotateZ(0deg);
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-25px); //sass-lint:disable-line no-vendor-prefixes
        }

        #{$faq}__answer {
            display: none;
        }
    }

    &__question {
        background: $primary-color;
        position: relative;
        padding-right: $grid-padding !important; //sass-lint:disable-line no-important
        word-break: break-all;
        word-break: break-word; // for webkit

        #{$faq}__item:not(#{$faq}--collapsed) & {
            color: $white !important; //sass-lint:disable-line no-important
        }
    }

    &__arrow {
        fill: $tertiary-color;
        transition: transform 0.5s ease;
        position: absolute;
        right: $grid-padding-half;
        top: 50%;
        transform: translateY(-50%) rotateZ(180deg);
        width: 0.75rem;
    }

    &__answer {
        transition: all 0.5s ease;
        background-color: #f9f8f4; //sass-lint:disable-line no-color-hex no-color-literals
    }
}
