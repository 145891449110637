@import "../settings";

// achtergronden ---------------------------------------------
.bg-primary {
    background-color: $primary-color !important; // sass-lint:disable-line no-important
}

.bg-secondary {
    background-color: $secondary-color !important; // sass-lint:disable-line no-important
}

.bg-tertiary {
    background-color: $tertiary-color !important; // sass-lint:disable-line no-important
}

.bg-quarternary {
    background-color: $quarternary-color !important; // sass-lint:disable-line no-important
}

.bg-muted {
    background-color: $bg-muted !important; // sass-lint:disable-line no-important
}

.bg-light {
    background-color: $bg-light !important; // sass-lint:disable-line no-important
}

.bg-medium {
    background-color: $bg-medium !important; // sass-lint:disable-line no-important
}

.bg-dark {
    background-color: $bg-dark !important; // sass-lint:disable-line no-important
}

.bg-white {
    background-color: $white !important; // sass-lint:disable-line no-important
}

//TODO @tony: dit hoort bij component backgroundimage, niet hier
.bg-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    //hoogtes (voor cards, dividers)
    &--xs {
        height: $bg-image-height-xs;
    }

    &--sm {
        height: $bg-image-height-sm;
    }

    &--md {
        height: $bg-image-height-md;

        @media (max-width: $mobile) {
            height: $bg-image-height-sm;
        }
    }

    &--lg {
        height: $bg-image-height-lg;
    }

    //minimale hoogtes, voor flex grids
    &--min-xs {
        min-height: $bg-image-height-xs;
    }

    &--min-sm {
        min-height: $bg-image-height-sm;
    }

    &--min-md {
        min-height: $bg-image-height-md;
    }

    &--min-lg {
        min-height: $bg-image-height-lg;
    }

    //uitlijning van foto/crop:
    &--tl {
        background-position: top left;
    }

    &--tr {
        background-position: top right;
    }

    &--contain {
        background-size: contain;
    }
}
