@import "./../../assets/scss/settings";

.slider {
    $slider: &;

    &__dots {
        position: relative;
        margin-top: -2rem;
        z-index: 11;

        .owl-dot {
            width: 0.75rem;
            height: 0.75rem;
            margin: $grid-margin-quarter;
            background: $bg-medium;
            cursor: pointer;

            &:hover {
                background: $bg-medium;
            }

            &.active {
                background: $primary-color;
            }
        }
    }

    &__button {
        position: absolute;
        bottom: calc(50% - 19px); // TODO calculate the 19px (half button size, half dots size)
        padding: calc(#{$grid-padding} * 0.75);
        z-index: 10;
        outline: none;
    }

    &__buttons {
        .owl-prev #{$slider}__button {
            left: -$grid-padding;

            @media (max-width: $mobile) {
                left: 0;
            }
        }

        .owl-next #{$slider}__button {
            right: -$grid-padding;

            @media (max-width: $mobile) {
                right: 0;
            }
        }
    }

    &__nav {
        #{$slider}__dots--round .owl-dot {
            border-radius: $slider-nav-radius;
        }

        #{$slider}__buttons--round {
            #{$slider}__button {
                border-radius: $slider-button-radius;
            }
        }
    }
}

.carslider {

    &__nav {
        position: absolute;
        right: 0;
        top: 15px;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    &__controls {
        background: $bg-transparent-dark;
        color: $white;
        border: 0;
        box-shadow: 0 0 0 0;

        &:first-of-type {
            border-bottom: 1px solid $border-light;
        }
    }
}

// Tijdelijke owl-slider styling

.owl {
    &-item {
        max-height: 27rem;

        img {
            margin-top: -1.9rem;
        }

        @media (max-width: $tablet) {
            max-height: 29rem;
        }

        @media (max-width: $mobile) {
            max-height: 19rem;
        }

        @media (max-width: 500px) {
            max-height: 15rem;
        }
    }
}

.carslider__thumbnails {
    .owl-item {
        @media (max-width: $mobile) {
            img {
                margin-top: unset;
            }
        }
    }
}

.logo {
    &__360 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        max-width: 5rem !important; // sass-lint:disable-line no-important
    }
}
