@import "./_Navbar.scss";

$distance: 1px;

.navbar {
    &-brand {
        &__item {
            fill: $white;

            &--secondary {
                fill: $secondary-color;
            }
        }
    }

    &--fixed {
        .brand {
            &__item {
                animation-duration: 1s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-out;
            }

            &__up {
                animation-name: stay-up-r;
            }

            &__left {
                animation-name: stay-left-r;
            }

            &__right {
                animation-name: stay-right-r;
            }

            &__downleft {
                animation-name: stay-downleft-r;
            }

            &__downright {
                animation-name: stay-downright-r;
            }
        }

        &:hover {
            .brand {
                &__up {
                    animation-name: stay-up;
                }

                &__left {
                    animation-name: stay-left;
                }

                &__right {
                    animation-name: stay-right;
                }

                &__downleft {
                    animation-name: stay-downleft;
                }

                &__downright {
                    animation-name: stay-downright;
                }
            }
        }
    }

    .nav {
        &__item--dropdown {
            &:hover {
                .nav__dropdown {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            @media (min-width: $tablet-medium + 1) {
                > .nav__link::after {
                    content: "";
                    position: absolute;
                    right: -12px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-top: 8px solid $white;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                }

                > .nav__link:hover::after {
                    border-top-color: $navbar-link-hover-color;
                }
            }
        }

        &__mobile-dropdown {
            position: absolute;
            right: 0;
            top: 20px;
            transform: translateY(-50%);
            cursor: pointer;
            width: 24px;
            height: 24px;
            transition: transform $transition-duration $transition-easing;

            &--active {
                transform: translateY(-50%) rotate(225deg);
            }

            &::before,
            &::after {
                content: "";
                width: 24px;
                height: 4px;
                background-color: $white;
                border-radius: 2px;
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &::after {
                width: 4px;
                height: 24px;
            }

            @media (min-width: $tablet-medium) {
                display: none;
            }
        }

        &__dropdown {
            position: absolute;
            background-color: lighten($primary-color, 10);
            margin-left: -15px;
            opacity: 0;
            pointer-events: none;
            transition: opacity $transition-duration $transition-easing;

            @media (max-width: $tablet-medium) {
                position: relative;
                opacity: 1;
                margin-left: 0;
                overflow: hidden;
            }

            .nav__link {
                white-space: nowrap;
                width: 100%;

                &--active {
                    border: 0;

                    span {
                        border-bottom: 2px solid $navbar-item-active-bg-color;
                    }
                }
            }
        }
    }
}

// animations
@keyframes stay-up {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-$distance);
    }
}

@keyframes stay-up-r {
    from {
        transform: translateY(-$distance);
    }

    to {
        transform: translateY(0);
    }
}


@keyframes stay-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-$distance);
    }
}

@keyframes stay-left-r {
    from {
        transform: translateX(-$distance);
    }

    to {
        transform: translateX(0);
    }
}


@keyframes stay-right {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX($distance);
    }
}

@keyframes stay-right-r {
    from {
        transform: translateX($distance);
    }

    to {
        transform: translateX(0);
    }
}


@keyframes stay-downleft {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-$distance, $distance);
    }
}

@keyframes stay-downleft-r {
    from {
        transform: translate(-$distance, $distance);
    }

    to {
        transform: translate(0);
    }
}

@keyframes stay-downright {
    from {
        transform: translate(0);
    }

    to {
        transform: translate($distance, $distance);
    }
}

@keyframes stay-downright-r {
    from {
        transform: translate($distance, $distance);
    }

    to {
        transform: translate(0);
    }
}
