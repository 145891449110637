@import "./../../assets/scss/settings";

//default lists
.list {
    $list: &;
    font-size: $font-size;

    &__item {
        position: relative;

        &--checkmark {
            list-style: none;
            position: relative;

            &::before {
                content: "\f00c";
                font-family: $icon-font;
                left: -30px;
                top: 0;
                position: absolute;
            }
        }
    }

    &__link {
        text-decoration: underline;
    }

    &--md {
        #{$list}__item {
            font-size: $font-size-md;
            line-height: $line-height;
        }
    }

    &--lg {
        #{$list}__item {
            font-size: $font-size-lg;
            line-height: $line-height;
        }
    }

    &--bullets {

        #{$list}__item {
            list-style: none;
            position: relative;

            &::before {
                position: absolute;
                top: 9px;
                content: "";
                left: -16px;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background-color: $secondary-color;
            }
        }
    }

    &--nobullets {
        padding-left: 0;

        #{$list}__item {
            list-style: none;
            position: relative;
        }
    }

    &--nolinks {

        #{$list}__link {
            text-decoration: none;
            color: inherit;
        }
    }

    &--checkmarks {
        list-style: none;
        position: relative;

        #{$list}__item {
            &::before {
                content: "\f00c";
                font-family: $icon-font;
                left: -30px;
                top: 0;
                position: absolute;
            }
        }
    }

    &--icons {
        list-style: none;
        position: relative;
        padding: 0;

        #{$list}__item {
            > svg {
                margin-right: 5px;
            }

            > i {
                margin-right: 5px;
            }

            i {
                width: 16px;
            }
        }

        #{$list}__link {
        }
    }

    &--inline {
        position: relative;
        padding: 0;
        display: flex;

        #{$list}__item {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            flex-direction: column;
        }
    }
}

.preferences-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    column-count: 3;
}
