@import "./../../assets/scss/settings";

//default button
.button {
    background-color: $btn-bg-color;
    color: $btn-text-color;
    display: inline-block;
    width: auto;
    padding: $btn-padding-y $btn-padding-x;
    text-align: center;
    transition: $btn-transition;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    font-family: $btn-font-family;
    line-height: $btn-line-height;
    cursor: pointer;
    text-decoration: none;
    border-radius: $btn-border-radius;
    border: $btn-border-radius;

    // reset voor inputs en buttons in safari


    //-webkit-appearance: none;

    // sass-lint:disable-line no-vendor-prefixes
    //hover

    &:hover {
        background-color: $btn-bg-hover-color;
        text-decoration: none;
    }


    //secondaire button
    
    &--secondary {
        background-color: $btn-bg-color-secondary;
        color: $btn-text-color-secondary;
        fill: $btn-text-color-secondary;

        &:hover {
            background-color: $btn-bg-hover-color-secondary;
            color: $btn-text-hover-color-secondary;
            fill: $btn-text-hover-color-secondary;
        }
    }

    //subtiele button, bijv. alleen border
    
    &--ghost {
        background: $btn-bg-color-ghost;
        color: $btn-text-color-ghost;
        fill: $btn-text-color-ghost;
        border: 1px solid $btn-text-color-ghost;

        &:hover {
            background-color: $btn-bg-hover-color-ghost; // sass-lint:disable-line no-color-literals
            color: $btn-text-hover-color-ghost;
        }
    }


    //omgedraaide styling van primary
    
    &--inverted {
        background: $btn-bg-color-inverted;
        color: $btn-text-color-inverted;

        &:hover {
            background-color: $btn-bg-hover-color-inverted;
            color: $btn-text-hover-color-inverted;
        }
    }


    //volledige breedte
    
    &--expand {
        width: 100%;
    }
    

    //vaste breedte
    
    &--fixed-width {
        width: $btn-fixed-size;
    }
    

    //selected/active
    
    &--active {
        color: $btn-text-color-active;
        background-color: $btn-bg-color-active;

        &:hover {
            background-color: $btn-bg-hover-color-active;
            color: $btn-text-hover-color-active;
        }
    }
    

    //button met icoon
    
    &--icon {
        &:before {
            font-family: $icon-font;
            margin-right: $grid-margin-third;
    
            //kleuren e.d. van iconen op dit niveau definiëren

        }

        //before

        &-only {
        
            //padding: 1rem !important; //sass-lint:disable-line no-important

            &:before {
                display: block;
                width: 1rem;
                height: 1rem;
                margin: 0;
            }
        }

        //per icoon in te stellen:

        &--example {
            &:before {
                font-family: $icon-font;
                content: "\f188";
            }
        }

        &--mobile {
            &:before {
                font-family: $icon-font;
                content: "\f3cd";
            }
        }

        &--mail {
            &:before {
                font-family: $icon-font;
                content: "\f0e0";
            }
        }

        //example
        
        &--previous {
            &:before {
                font-family: $icon-font;
                content: "\f104";
            }
        }

        &--next {
            &:before {
                font-family: $icon-font;
                content: "\f105";
            }
        }

        &--case {
            &:before {
                font-family: $icon-font;
                content: "\f275";
            }
        }

        &--list:before {
            font-family: $icon-font;
            content: "\f0c9";
        }

        &--grid:before {
            font-family: $icon-font;
            content: "\f00a";
        }

        &--cross:before {
            font-family: $icon-font;
            content: "\f00d";
        }

        &--whatsapp:before {
            font-family: $icon-font;
            content: "\f232"; // needs to be f232 for whatsapp but its not working
        }
    }

    //icon

}

//button
