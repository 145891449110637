@import "global";
@import "basics";

//links -----------------------
a {
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

//divider ----------------------
hr,
.hr {
    height: 1px;
    color: $bg-medium;
    border-top: 1px solid $border-medium;
    margin: 1.2em 0; //todo: var margins gebruiken

    &--stretch {
        border-top: 1px solid $border-light;
        margin-left: -39px;
        margin-right: -39px;
        margin-bottom: 0;
        margin-top: 0;
    }

    &--dotted {
        border-top: 1px dashed $bg-muted;
    }

    &--medium {
        border-color: $text-color-muted;
    }

    &--vert {
        height: 100%;
        color: $bg-medium;
        border-left: 1px solid $border-medium;
        margin: 0 auto;
        width: 1px;
    }
}

// geselecteerde tekst ---------------------------------------------
::selection {
    background: $primary-color;
    color: $white !important; // sass-lint:disable-line no-important
}

// sass-lint:disable no-vendor-prefixes
::-moz-selection {
    background: $primary-color;
    color: $white;
}

// sass-lint:enable no-vendor-prefixes

.fancybox-close-small {
    height: 75px;
    width: 50px;
}

// fix voor het verspringen van de navigatie van de lsider op de voorraad pagina.
.car-item-fix {
    min-height: 260px;
}

.fancybox-iframe {

    @media(max-width: $tablet-small) {
        width: 94% !important; // sass-lint:disable-line no-important
    }
}

.product-card {

    &__title {
        text-align: right;
        margin: 0;

        @media(max-width: $tablet-small) {
            text-align: left;
            margin-top: 1rem !important; // sass-lint:disable-line no-important
        }
    }

    &__button {
        margin-right: 15px;

        @media(max-width: 1023px) {
            position: relative !important; // sass-lint:disable-line no-important
            width: 100%;
            margin-right: 0;
            margin-top: 1rem;
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    &__loginlink {
        text-transform: uppercase;
        transition: $btn-transition;
        color: $secondary-color !important; // sass-lint:disable-line no-important
        @media(max-width: $tablet-medium) {
            margin-top: 10px;
            margin-bottom: 0px !important; // sass-lint:disable-line no-important
        }

        &:hover {
            color: darken($secondary-color, 10) !important; // sass-lint:disable-line no-important
            cursor: pointer;
            text-decoration: underline;
        }

        &--small {
            margin-top: 8px;
        }

    }
}

.item {

    &--spinner {

        iframe {
            height: 100%;
            width: 100%;
            min-height: 440px;
        }
    }
}

.alert-warning {
    display: flex;
    justify-content: flex-end;

    a {
        color: $white;
        font-weight: bold;
        text-decoration: none;
        font-size: 18px;
        background: $secondary-color;
        padding: .5rem 2rem;

        span {
            text-decoration: underline;
        }
    }
}
