@import "../settings";

// sass-lint:disable no-important

// onderstaande loop maakt helpers aan voor margins en paddings.
// De output is bijv ".margin-l-xs { margin-left: 10px !important; }"

// Zorg niet voor onnodige code:
// geef aan welke margins terug moeten komen als utility-classes. Haal de vars uit de global/settings.
// (bijv. 0, $grid-margin-quarter, $grid-margin-third, $grid-margin-half, $grid-margin, $grid-margin-double)
$margins: 0, auto, $grid-margin-third, $grid-margin-half, $grid-margin, $grid-margin-double;

// pas de bijbehorende class-namen aan: (bijv. xxs, xs, sm, md, lg)
$margin-classes: 0, a, xs, sm, md, lg; // moet altijd kloppen met $margins!
// voer de benodigde sides in: (keuze uit top, bottom, left, right)
$sides: top, bottom, left, right;

@each $margin in $margins {
    $i: index($margins, $margin);
    $current-class: nth($margin-classes, $i);

    //margin: x; (alle kanten)
    .margin-#{$current-class} {
        margin: #{$margin} !important;
    }

    //margin per kant (bijv alleen margin-left: x;)
    @each $side in $sides {
        .margin-#{str-slice($side, 0, 1)}-#{$current-class} {
            margin-#{$side}: #{$margin} !important;
        }
    }
}

// zelfde verhaal voor padding
// (bijv. 0, $grid-padding-quarter, $grid-padding-third, $grid-padding-half, $grid-padding, $grid-padding-double)
$paddings: 0, $grid-margin-quarter, $grid-padding-third, $grid-padding-half, $grid-padding, $grid-padding-double;
$padding-classes: 0, xxs, xs, sm, md, lg;
$sides: top, bottom, left, right;

@each $padding in $paddings {
    $i: index($paddings, $padding);
    $current-class: nth($padding-classes, $i);

    //padding: x; (alle kanten)
    .padding-#{$current-class} {
        padding: #{$padding} !important;
    }

    @each $side in $sides {
        .padding-#{str-slice($side, 0, 1)}-#{$current-class} {
            padding-#{$side}: #{$padding} !important;
        }
    }
}

.padding-t-0-sm {
    @media (min-width: $tablet-small) and (max-width: $tablet-large) {
        padding-top: 0 !important;
    }
}

.margin-t-special {
    @media (max-width: $tablet-medium) {
        margin-top: 3rem !important;
    }

    @media (max-width: $mobile) {
        margin-top: 2rem !important;
    }

    @media (max-width: $mobile-medium) {
        margin-top: 1rem !important;
    }
}

// Padding reset voor responsive
.padding-sm-0 {
    @media (max-width: $mobile) {
        padding: 0 !important;
    }
}

.padding-md-0 {
    @media (max-width: $tablet-medium) {
        padding: 0 !important;
    }
}

// Margin reset voor responsive
.margin-sm-0 {
    @media (max-width: $mobile) {
        margin: 0 !important;
    }
}

.margin-md-0 {
    @media (max-width: $tablet-medium) {
        margin: 0 !important;
    }
}


.padding-tablet-5 {

    @media (max-width: $tablet-medium) {
        padding: 5rem;
    }

}
