@import "./../../assets/scss/settings";
@import "Buttons.scss";

//Specieke buttonstyling voor Axero
.button {
    $button: &;

    font: normal normal 600 #{$font-size}/1.1rem $primary-font;
    text-transform: uppercase;
    background-color: $link-color;
    color: $white;
    padding: 1rem 1.5rem;

    &:hover {
        background-color: $hover-color;
        color: $white;
    }

    &--primary {
        background-color: $primary-color;

        &:hover {
            background-color: darken($primary-color, 10);
        }

        &#{$button}--active {
            background-color: $tertiary-color;
        }
    }

    &--secondary {
        background-color: $secondary-color;

        &:hover {
            background-color: darken($secondary-color, 10);
        }
    }

    &--tertiary {
        font-size: $font-size-sm;
        background-color: $tertiary-color;

        &:hover {
            background-color: darken($tertiary-color, 10);
        }
    }

    &--ghost {
        background: transparent;
        color: $link-color;
        border: 0;
        box-shadow: inset 0 0 0 1px $link-color;

        &:hover {
            background-color: $link-color;
        }
    }

    &--reserve {
        background-color: $secondary-color;

        &:hover {
            background-color: darken($secondary-color, 10);
            cursor: pointer;
        }
    }

    &--price {
        background-color: $secondary-color;

        &:hover {
            background-color: darken($secondary-color, 10);
            color: $secondary-color;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &--text-link {
        border: 0;
        background: transparent;
        color: $link-color;
        text-decoration: none;
        font-family: $primary-font;
        font-size: $font-size;
    }

    &--active {
        background-color: $hover-color;
    }

    &--disabled {
        background-color: $text-color-muted;
        cursor: default;

        &:hover {
            background-color: $text-color-light;
        }
    }
}
//button
