// sass-lint:disable no-important
.flex {
    display: flex;
    flex-wrap: wrap;

    &--start {
        justify-content: flex-start !important;
    }

    &--center-x {
        justify-content: center !important;
    }

    &--end {
        justify-content: flex-end !important;
    }

    &--top {
        align-items: flex-start !important;
    }

    &--stretch {
        align-items: stretch !important;
    }

    &--grow {
        flex-grow: 1 !important;
    }

    &--center-y {
        align-items: center !important;
    }

    &--bottom {
        align-items: flex-end !important;
    }

    &--space-around {
        justify-content: space-around !important;
    }

    &--space-between {
        justify-content: space-between !important;
    }

    &--space-evenly {
        justify-content: space-evenly !important;
    }

    &--center-content {
        justify-content: center;
    }

    &--row-reverse {
        flex-direction: row-reverse !important;
    }

    &--column {
        flex-direction: column !important;
    }

    &--nostretch {
        align-items: baseline;
    }

    &--self-nostretch {
        align-self: baseline;
    }

    &--column-reverse {
        flex-direction: column-reverse !important;
    }

    &--wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    &--nowrap {
        flex-wrap: nowrap !important;
    }

    &--first {
        order: -1;
    }

    &--reset-order {
        order: 0;
    }

    &--last {
        order: 1;
    }

    &--align-self-start {
        align-self: flex-start !important;
    }

    &--align-self-center {
        align-self: center !important;
    }

    &--align-self-end {
        align-self: flex-end !important;
    }

    //voor als je op mobiel wilt stacken, maar op grotere resoluties wel naast elkaar wilt tonen
    @media(min-width: $screen-sm-min) {
        &--row-sm {
            flex-direction: row !important;
        }
    }

    @media(min-width: $screen-md-min) {
        &--row-md {
            flex-direction: row !important;
        }
    }

    @media(min-width: $screen-lg-min) {
        &--row-lg {
            flex-direction: row !important;
        }
    }
}

.stretch-y {
    height: 100%;
}

.stretch-x {
    width: 100% !important;
}

.order-first {
    order: -1 !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

.order-last {
    order: 6 !important;
}

@media (max-width: 576px) {
    .order-sm-first {
        order: -1 !important;
    }

    .order-sm-0 {
        order: 0 !important;
    }

    .order-sm-1 {
        order: 1 !important;
    }

    .order-sm-2 {
        order: 2 !important;
    }

    .order-sm-3 {
        order: 3 !important;
    }

    .order-sm-4 {
        order: 4 !important;
    }

    .order-sm-5 {
        order: 5 !important;
    }

    .order-sm-last {
        order: 6 !important;
    }
}

@media (max-width: 768px) {
    .order-md-first {
        order: -1 !important;
    }

    .order-md-0 {
        order: 0 !important;
    }

    .order-md-1 {
        order: 1 !important;
    }

    .order-md-2 {
        order: 2 !important;
    }

    .order-md-3 {
        order: 3 !important;
    }

    .order-md-4 {
        order: 4 !important;
    }

    .order-md-5 {
        order: 5 !important;
    }

    .order-md-last {
        order: 6 !important;
    }

}

@media (max-width: 992px) {
    .order-lg-first {
        order: -1 !important;
    }

    .order-lg-0 {
        order: 0 !important;
    }

    .order-lg-1 {
        order: 1 !important;
    }

    .order-lg-2 {
        order: 2 !important;
    }

    .order-lg-3 {
        order: 3 !important;
    }

    .order-lg-4 {
        order: 4 !important;
    }

    .order-lg-5 {
        order: 5 !important;
    }

    .order-lg-last {
        order: 6 !important;
    }
}

@media (max-width: 1200px) {
    .order-xl-first {
        order: -1 !important;
    }

    .order-xl-0 {
        order: 0 !important;
    }

    .order-xl-1 {
        order: 1 !important;
    }

    .order-xl-2 {
        order: 2 !important;
    }

    .order-xl-3 {
        order: 3 !important;
    }

    .order-xl-4 {
        order: 4 !important;
    }

    .order-xl-5 {
        order: 5 !important;
    }

    .order-xl-last {
        order: 6 !important;
    }

}
